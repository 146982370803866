:root {
  --white: #efefef;
  --orange: #f4a261; /*#d1345b;*/
  --teal: #28afb0; /*#0b7a75;*/
  --purple: #8380B6; /*#3454d1;*/
  --yellow: #d7c9aa;
  --black: #070707;

  --transluscent-white: rgba(239, 239, 239, 0.3);
  --transluscent-black: rgba(7, 7, 7, 0.8);

  --nav-height: 64px;
  --gradient-color-size: 500px;
  --border-size: 4px;
}

.debug {
  outline: 1px solid red !important;
}

html {
  scroll-padding-top: var(--nav-height);
  font-family: "Roboto", sans-serif;
  background-color: var(--black);
}

body {
  background: repeating-linear-gradient(
    170deg,
    var(--purple) 0 80vh,
    var(--orange) 80vh calc(80vh * 2),
    var(--teal) calc(80vh * 2) calc(80vh * 3)
  );
  color: var(--white) !important;
  margin: 0;
  padding: 0;
}

/* borders */

.green-blue-border {
  border-top: 8px solid var(--teal);
  border-right: 8px solid var(--purple);
  border-bottom: 8px solid var(--purple);
  border-left: 8px solid var(--teal);
}

.blue-red-border {
  border-top: 8px solid var(--purple);
  border-right: 8px solid var(--orange);
  border-bottom: 8px solid var(--orange);
  border-left: 8px solid var(--purple);
}

.red-green-border {
  border-top: 8px solid var(--orange);
  border-right: 8px solid var(--teal);
  border-bottom: 8px solid var(--teal);
  border-left: 8px solid var(--orange);
}

.light-border {
  border: 8px solid var(--transluscent-white);
}

.dark-border {
  border: 8px solid var(--transluscent-black);
}

.rounded-lg {
  border-radius: 1rem !important;
}

/* Backgrounds */

.bg-black {
  background-color: var(--black) !important;
}

.bg-transluscent-black {
  background-color: var(--transluscent-black) !important;
}

.bg-blue {
  background-color: var(--purple) !important;
}

.bg-green {
  background-color: var(--teal) !important;
}

.bg-red {
  background-color: var(--orange) !important;
}

.bw {
  filter: saturate(0%);
}

/* Fonts */

.big-font {
  font-family: "Secular One", sans-serif !important;
}

a:hover {
  color: var(--purple);
}

/* Text */

.text-transluscent-white {
  color: var(--transluscent-white) !important;
}

.text-transluscent-black {
  color: var(--transluscent-black) !important;
}

/* Generic sizing */

.w-10 {
  width: 10% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.w-60 {
  width: 60% !important;
}

.w-70 {
  width: 70% !important;
}

.w-75 {
  width: 75% !important;
}

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

li {
  margin: 0.5rem 0;
}
